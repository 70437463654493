<template>
    <div>
         <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
                <div>
                <b-card-title>NFT Holders</b-card-title>
                </div>
               <div class="d-flex align-items-center">
          <div>
            <b-form-group
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group >
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Filter by Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
          </div>
          <div class="ml-1">
        <feather-icon
          icon="DownloadIcon"
          size="21"
           class="cursor-pointer"
           @click="downloadfile"
            v-b-tooltip.hover.top="'Download CSV'"
        />
        </div>
      </div>
            </b-card-header>
            <b-table
                 :busy="isBusy"
            :items="holders"
            :current-page="currentPage"
      striped
      responsive
      hover
       style="width: 100%;" fixed
       class="mb-0"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      :per-page="perPage"
       >
       <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
       <template #cell(mintAddress)="data">
        <div class="w-100 text-truncate" v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
        <template #cell(owner)="data">
        <div class="w-100 text-truncate" v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
      <template #cell(transactionAddress)="data">
        <div class="w-100 text-truncate" v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
         <template #cell(time)="data">
          <b-badge variant="light-success">
            <h6>{{data.value}}</h6>
          </b-badge>
      </template>
            </b-table>
             <b-col cols="12">
        <b-row class="match-height">
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              class="my-0 mt-1 mb-1"
            />
          </b-col>
        </b-row>
      </b-col>
        </b-card>

    </div>
</template>
<script>
import {BCard,BCardTitle,BCardHeader,BTable,BPagination,VBTooltip,BRow,BCol,BBadge,
BFormInput,BFormGroup,BInputGroupAppend,BInputGroup,BButton,BSpinner} from "bootstrap-vue"
export default {
       directives: {'b-tooltip': VBTooltip},
    components:{BCard,BCardTitle,BCardHeader,BTable,BPagination,BRow,BCol,BBadge,BFormInput,BFormGroup,
    BInputGroupAppend,BInputGroup,BButton,BSpinner},
    props:["number"],
    data(){
        return{
        perPage: 25,
        totalRows: 1,
        currentPage: 1,
         fields: [{ key: 'mintAddress', label: 'Mint Address',thStyle: {width: '30%'} }, 
         {key:'owner',label:'Owner',thStyle: {width: '30%'}}, 
      {label:'Transaction Address',key:'transactionAddress',thStyle: {width: '25%'}},
      {label:'Ownership Datetime',key:'time',thStyle: {width: '15%'}}],
            holders:[],
        filter: null,
        filterOn: [],
        isBusy: false
        }
    },
    mounted(){
       this.getdataCollection()
    },
    methods:{
         downloadfile:async function(){
          
  const link = document.createElement("a");
          link.href = "/api/v1/sel/bgkcsv";
          link.setAttribute("download", 'CollectionHolders.xlsx');
        document.body.appendChild(link);
        link.click();
   
        },
        getdataCollection:function(){
       const vm = this;
      const axios = require('axios');
       this.isBusy = true;
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      axios.get('/api/v1/sel/bgokbr/'+this.number,config).then(function (response) {
        console.log(response.data);
        vm.holders=response.data.info
        vm.totalRows=vm.holders.length;    
         vm.isBusy = false; 
        })
        .catch(err => {
          console.log(err);
        });
    },
        
  
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
     numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    }
}
</script>