<template>
<div>
    <b-row class="match-height">
        <b-col lg="3">
             <CardTransparent
          color=""
          :icon="nft.data.avatar"
          statistic="1"
          statistic-title="Collection Name"
        />
        </b-col>
        <b-col lg="3">
             <CardTransparent
          color=""
          icon=""
          :statistic="nft.data.collection"
          statistic-title="Collection Name"
        />
        </b-col>
         <b-col lg="3">
             <CardTransparent
          color=""
          icon=""
          :statistic="nft.data.items"
          statistic-title="Collection Items"
        />
        </b-col>
         <b-col lg="3">
             <CardTransparent
          color=""
          icon=""
          :statistic="nft.floorPrice/1000000000"
          statistic-title="Collection Floor Price"
        />
        </b-col>
    </b-row>
    <b-card-group deck>
      <b-card>
  <b-tabs content-class="mt-2" pills>
    <b-tab 
    active>
    <template #title>
          <feather-icon
            icon="UserCheckIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{nft.data.collection}} Holders</span>
        </template>
        <NFTHolders :number="number"/>
                    
    </b-tab>

  </b-tabs>
  </b-card>
 </b-card-group>
</div>
</template>
<script>
import {BRow,BCol,BTable,BCard,BCardTitle,BCardHeader,VBTooltip,BBadge,BTabs,BTab,BCardGroup} from "bootstrap-vue"
import CardTransparent from "@core/components/statistics-cards/StatisticCardHorizontalTransparent.vue" 
import NFTHolders from "@/views/nftanalysis/NFTHolders.vue"
import Ripple from "vue-ripple-directive"
export default {
        directives: {'b-tooltip': VBTooltip,
   Ripple},
    components:{BRow,BCol,BTable,BCard,BCardTitle,BCardHeader,BBadge,CardTransparent,BTabs,BTab,BCardGroup,NFTHolders},
    data(){
         const infoX=JSON.parse(localStorage.getItem("dataNFT"));
         console.log(infoX);
        return{
            nft:infoX.nft,
            number:infoX.nft.data.collection=="Okay Bears"?2:1
        }
    },
    mounted(){
       

    },
    methods:{
        

    }
    
}
</script>